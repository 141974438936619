/**
*  UI-REFACTORED
*    FROM --> ./legacy-web/src/main/js/js/aa/contactAA/services/groupMeetingFormService.js
*    TO   --> ./legacy-web/src/main/assets/js/contact-aa/services/group-meeting-form-service.js
**/

/* global contactModule:true */

angular.module('com.aa.contact').service('GroupMeetingFormService', function ($rootScope, $timeout) {

    var groupMeetingEventService = {
        showDeleteEventDialog: function (events) {
            contactModule.contactAA.util.aaDialog('deleteEvent', {
                buttons: [
                    {
                        name: contactModule.contactAA.getProperty('deleteEventText'),
                        cssClass: "btn",
                        closeDialog: true,
                        callback: function () {
                            if (events.length <= 1) {
                                return;
                            }
                            events.splice(events.length - 1, 1);
                            $rootScope.$apply();
                            scrollToAddEvent($timeout);
                        }
                    },
                    {
                        name: contactModule.contactAA.getProperty('keepEventText'),
                        cssClass: "btn",
                        callback: function () {
                            focusOnLastEventTitle();
                        }
                    }],
                onClose: function () {
                    focusOnLastEventTitle();
                },
                width: 'small',
                autoOpen: false,
                closeDialog: true,
                toggleScroll: true,
                position: 'center',
                closeOnEscape: false
            });
            contactModule.contactAA.util.aaDialog('deleteEvent').openDialog();

            var length = events.length;
            $j("p.no-margin span").empty();
            $j("p.no-margin span").append(length + '?');
        }
    };

    var focusOnLastEventTitle = function () {
        var element = angular.element('.eventsGroup').last().find('a');
        contactModule.contactAA.setFocusOn(element);
    };

    var scrollToAddEvent = function ($timeout) {
        $timeout(function () {
            var $target = angular.element("#addEvent");
            var top = $target.offset().top - 300;
            angular.element('html, body').stop(true).animate({
                scrollTop: top
            }, "slow");
            $target.focus();
        }, 0);
    };

    return groupMeetingEventService;
});
